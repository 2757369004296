import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        authToken: sessionStorage.getItem('token') || null,
        user: JSON.parse(sessionStorage.getItem('userInfo')) || null,
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setToken(state, token) {
            state.authToken = token
        },
        logout(state) {
            sessionStorage.clear()
            state.authToken = null
            state.user = null
        }
    },
    actions: {
        setUser({commit}, user) {
            sessionStorage.setItem('userInfo', JSON.stringify(user));
            commit('setUser', user)
        },
        setToken({commit}, token) {
            sessionStorage.setItem('token', token);
            commit('setToken', token)
        },
    },
    modules: {}
})
