// import debugData from './debugData.js'
import https from 'https'
// 此处第二个参数vm，就是我们在页面使用的this，你可以通过vm获取vuex等操作，更多内容详见uView对拦截器的介绍部分：
// https://uviewui.com/js/http.html#%E4%BD%95%E8%B0%93%E8%AF%B7%E6%B1%82%E6%8B%A6%E6%88%AA%EF%BC%9F
const install = (Vue, vm) => {

    var IS_DEBUG = false;
    var BASE_URL = IS_DEBUG ? 'http://192.168.1.5:8081' : 'https://open.yxl360.com';
    var AppInfo = {
        appid: "wxede526d360b5efd5",
    }

    /**
     * TODO API接口声明
     */
    Vue.prototype.$api = {
        request: request,
        appInfo: AppInfo,
        //登陆
        login: data => {
            return request('/plateform/user/login', data)
        },
        //注册
        register: ({phoneNo, password, validCode}) => {
            return request('/plateform/user/register', {
                'phoneNo': phoneNo,
                'password': password,
                'validCode': validCode,
            })
        },
        //忘记密码
        forgetPassword: ({phoneNo, password, validCode}) => {
            return request('/plateform/user/forgetPassword', {
                'phoneNo': phoneNo,
                'password': password,
                'validCode': validCode,
            })
        },
        //发送验证码
        sendValidCode: ({phoneNo, funcType}) => {
            return request('/plateform/sms/sendMessage', {
                "phoneNo": phoneNo,
                "funcType": funcType // USER_REGISTER-注册,FORGET_PASSWORD-忘记密码
            })
        },
        //学校、年级、班级列表查询接口
        queryDeptList: ({parentId}) => {
            return request('/plateform/dept/list', {
                parentId: parentId
            })
        },
        //查询支付选项列表
        queryPayChargeDetail: ({classId, studentName}) => {
            return request('/plateform/manage/query/payDetail', {
                'classId': classId,
                'studentName': studentName
            })
        },
        //查询交易记录
        queryOrderList: ({phone, currentPage, pageSize}) => {
            return request('/plateform/tradeInfo/pagingSelect', {
                'phone': phone,
                'currentPage': currentPage,
                'pageSize': pageSize,
            })
        },
        //请求下单
        pay: ({itemId, prodType}) => {
            console.log(prodType)
            return request('/plateform/trade/pay', {
                'itemId': itemId,
                // 'prodType': prodType,
            })
        },

    }


    /**
     *
     * ========================================================================
     * @param url
     * @param data
     * @param hideLoading
     * @param method
     * @returns {Promise<unknown>}
     */

        //请求封装
    var request = function request(url, data = {}, hideLoading = true, method = 'post') {
            let _url = BASE_URL + url;

            //获取微信的token
            let token = vm.$store.state.authToken;
            console.log("@TEST..token:", token)
            let header = {};
            if (token) {
                data['authToken'] = token;
                header = {
                    // 'Content-Type': 'application/x-www-form-urlencoded'
                    authToken: token
                };
            }
            // if (IS_DEBUG) {
            //     return debugData.data(url);
            // }
            if (data == null) {
                data = {}
            }
            let loading;
            if (!hideLoading) {
                //TODO showLoading
                console.log("需要弹窗")
                loading = vm.$loading({
                    lock: true,
                    target: '.loading',
                    spinner: 'el-icon-loading',
                    // background: 'rgba(0, 0, 0, 0.7)'
                })
            }

            return new Promise(function (resolve, reject) {
                let successCallBack = success => {
                    console.log("@Api 请求成功：", success);
                    if (success.data.code === '000000') {
                        resolve(success.data.data);
                    } else {
                        if (success.data.code === '900009') {
                            vm.$store.commit('logout');
                            vm.$router.push({path: "/"})
                            reject(success.data);
                        } else {
                            reject(success.data)
                            // if (data.showErr || true) {
                            // 	// uni.showToast({
                            // 	// 	title: request.data.message,
                            // 	// 	icon: 'none',
                            // 	// 	duration: 3000
                            // 	// })
                            // 	// vm.$u.toast(request.data.message);
                            // }
                        }
                    }
                };

                let errorCallback = error => {
                    console.log("@Api 请求失败：", error);
                    reject(error);
                };

                let complete = function () {
                    if (loading) {
                        loading.close();
                    }
                }
                let client = vm.axios.create({
                    httpsAgent: new https.Agent({
                        rejectUnauthorized: false
                    })
                })
                if (method === 'post') {
                    client.post(_url, data, {
                        headers: header
                    }).then(successCallBack).catch(errorCallback).finally(complete)
                } else {
                    client.get(_url, {
                        params: data,
                        headers: header
                    }).then(successCallBack).catch(errorCallback).finally(complete)
                }
            });
        };

}

export default {
    install
}
