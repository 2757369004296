<template>
    <svg :class="svgClass" aria-hidden="true" v-on="$listeners" :width="width" :height="height">
        <use :xlink:href="iconName"/>
    </svg>
</template>

<script>
    export default {
        name: 'SvgIcon',
        props: {
            icon: {
                type: String,
                required: true
            },
            className: {
                type: String,
                default: ''
            },
            width: {
                type: String,
                default: '50',
            },
            height: {
                type: String,
                default: '50',
            },
        },
        computed: {
            iconName() {
                return `#icon-${this.icon}`
            },
            svgClass() {
                if (this.className) {
                    return 'svg-icon ' + this.className
                } else {
                    return 'svg-icon'
                }
            }
        }
    }
</script>

<style scoped>
    .svg-icon {
        /*width: 10em;*/
        /*height: 10em;*/
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }
</style>
复制代码
