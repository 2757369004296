import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: () => import('../views/login/LoginPage')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/login/RegisterPage')
    },
    {
        path: '/forgetPwd',
        name: 'ForgetPwd',
        component: () => import('../views/user/ForgetPwdPage')
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/home/HomePage')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/protocol',
        name: 'Protocol',
        component: () => import('../views/user/ProtocolPage'),
        props: route => ({type: route.query['type'],
			title: route.query['title'], 
			content: route.query['content'],
		}),
    },
    {
        path: '/charge',
        name: 'Charge',
        component: () => import('../views/charge/ChargePage')
    },
    {
        path: '/order',
        name: 'Order',
        component: () => import('../views/order/OrderPage')
    },
]

const router = new VueRouter({
    routes
})


export default router
