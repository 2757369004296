import {mapState, mapActions} from 'vuex'

//全局的工具方法
export default {
    computed: {
        ...mapState({user: 'user'})
    },
    methods: {
        ...mapActions(['setUser', 'setToken']),
        //获取卡类型
        _getCardTypeStr(type) {
            if (type == "01") {
                return "一卡通"
            } else if (type == '02') {
                return "缴费卡"
            }
        },
        //检测金额
        _checkMoney(obj) {
            if (!/^\d+\.?\d{0,2}$/.test(obj.value)) {
                // obj.value = obj.value;
                obj.value = obj.value.substring(0, obj.value.length - 1);
            }
        },
        _goBack() {
            // this.$notify.success("222")
            // console.log("222")
            this.$router.go(-1)
        },
        //处理表单校验失败问题
        _showErrorValidMsg(error) {
            if (error && Object.keys(error).length > 0) {
                let msg = error[Object.keys(error)[0]][0]
                this.$notify.error(msg.message);
            } else {
                this.$notify.error("请检查表单输入");
            }
        }
    }
}
