import Vue from 'vue'

//注册一个全局自定义指令
Vue.directive('anim-click', {
    //每当指令绑定到元素上时，会立即执行这个bind函数，只执行一次
    bind: function (el) {
        el.addEventListener("click", function () {
            el.classList.add("anim-click-scale")
        })
        el.addEventListener("animationend", function () {
            el.classList.remove("anim-click-scale");
        });
        el.addEventListener("WebkitAnimationEnd", function () {
            el.classList.remove("anim-click-scale");
        });
    }
})


// let _removeAllCss = function (el) {
//     if (el && el.classList && el.classList.contains('anim-click-scale')) {
//         console.log("@TEST...", el)
//         el.classList.remove('anim-click-scale')
//     }
//     if (el.children && el.children.length > 0) {
//         el.children.forEach((item) => {
//             _removeAllCss(item)
//         })
//     }
// }
//
// //----移除不相干事件
// Vue.mixin({
//     activated() {
//         _removeAllCss(this.$el)
//     },
//     deactivated() {
//         _removeAllCss(this.$el)
//     }
// });
