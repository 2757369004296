import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI)
import less from 'less'

Vue.use(less)
Vue.config.productionTip = false

//引入全局混淆
import utilsMixins from './utils/utils.mixin.js'

Vue.mixin(utilsMixins);

//==导入自己的动画
import './utils/app-anim'


// svg 文件
import svgIcon from '@/components/SvgIcon.vue' // svg组件
// 注册全局插件
Vue.component('svg-icon', svgIcon)
import '@/assets/svg.js'
/**
 * 请求工具
 */
import vueAxios from 'vue-axios'
import axios from 'axios'

Vue.use(vueAxios, axios)
//挂载vue
let app = new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')

//配置api --
import api from './utils/api.js';

Vue.use(api, app);
